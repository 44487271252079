<template>
    <div>
        <div class="back-container">
            <button class="btn-unstyled btn-back" @click="goBack()">
                <i class="fas fa-chevron-left"></i>
                <span>{{lang.spielStarten.buttonTextBack}}</span>
            </button>
        </div>
        <PlayGameComponent :spielID="spielID" :turnierID="turnierID"/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import PlayGameComponent from '@/components/Spiele/PlayGameComponent.vue'

export default {
    name: 'PlayGame',
    props: ['spielID', 'turnierID'],
    components: {
        PlayGameComponent
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.spiele.loadingStatus;
        }
    },
    methods: {
        goBack() {
            router.go(-1);
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base';

.back-container {
    position: relative;

    .btn-back {
        font-size: 1.7rem;
        color: $color-fliestext;
        margin-left: 15px;
        position: absolute;
        z-index: 2;
        height: 60px;

        span {
            margin-left: 10px;
            font-size: 1.9rem;
        }

        i {
            font-size: 1.6rem;
        }
    }
}
</style>
