import { render, staticRenderFns } from "./PlayGame.vue?vue&type=template&id=12a5f7f8&scoped=true&"
import script from "./PlayGame.vue?vue&type=script&lang=js&"
export * from "./PlayGame.vue?vue&type=script&lang=js&"
import style0 from "./PlayGame.vue?vue&type=style&index=0&id=12a5f7f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a5f7f8",
  null
  
)

export default component.exports